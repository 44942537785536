import { ConfigProvider } from 'antd';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from './components/Grid';

function App() {
  const [traders, setTraders] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setInterval(getTraders, 1000);
  }, []);

  const getTraders = async() => {
    try {
      const response = await axios("http://moneymakingmachine.live/api");
      console.log("response", response);
      if(response?.data && response?.data?.length > 0) setTraders(response?.data);
    }
    catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(traders && traders.length > 0) {
      const data = [];
      for(let trader of traders) {  
        const currentData = {
          symbol: trader.symbol,
          profit: trader.profit,
          profitTaken: trader.profitTaken,
          totalProfit: trader.totalProfit,
          takeProfit: trader.takeProfit,
          speed: trader.speed,
          aim: trader.aim,
          stopLoss: trader.stopLoss,
          moneyIn: trader.moneyIn,
          levels: trader.levels,
          transactions: trader.transactions
        }
        data.push(currentData);
      }

      console.log(data);
      setData(data);
    }

  }, [traders]);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#FFD700",
            colorPrimaryBorder: "#FFD700",
            colorText: "#FFD700",
          },
        }}
      >
        <div className='row wrap justifyStart alignStart'>
          {data && data.length > 0 && data?.map(obj => <Grid data={obj} />)}
        </div>
      </ConfigProvider>
    </div>
  );
}

export default App;
