import Line from '../Line';
import styles from './level.module.css';

const Level = ({data, children}) => {

  return (
    <div className={styles.container}>
      <div className={`${styles.levelContainer} ${data.type === "PRICE" ? styles.priceContainer : ""}`} >
        {children?.map(obj => <Line key={Math.random() * 100} data={obj} />)}
      </div>
      <span className={styles.price}>{Number(data.price).toFixed(2)}</span>
    </div>

  );
}

export default Level;
