import styles from './grid.module.css';
import Level from '../Level';

const Grid = ({data}) => {
  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid} style={{border: `3px solid ${Number(data.profit) >= Number(data.aim) ? "#FFD700" : Number(data.profit) > 0 ? "green" : "red"}`}}>
        <div className={styles.header}>
          <div className='column'>
            <span className={styles.headerText}>{data.symbol}</span>
            <span className={styles.smallText}>TOTAL: {Number(data.totalProfit).toFixed(2)}$</span>
            <span className={styles.smallText}>AIM: {Number(data.aim).toFixed(2)}$</span>
            <span className={styles.smallText}>MONEY: {Number(data.moneyIn).toFixed(2)}$</span>
            <span className={styles.smallText}>TAKEN: {Number(data.profitTaken).toFixed(2)}$</span>
            <span className={styles.smallText}>SPEED: {Number(data.speed).toFixed(2)}</span>
            <span className={styles.smallText}>TP: {Number(data.takeProfit).toFixed(2)}</span>
            <span className={styles.smallText}>SL: {Number(data.stopLoss).toFixed(2)}</span>
          </div>
          <div className='column'>
            <span className={styles.headerText}>{Number(data.profit).toFixed(2) || 0}$</span>
          </div>
        </div>
        <div className={styles.levels}>
          {data.levels?.map(obj => <Level key={Math.random() * 100} data={obj} children={data.transactions?.filter(tx => tx.price === obj.price)} />)}
        </div>
      </div>
    </div>
  );
}

export default Grid;
